$(function(){
  $('.js-check-all-targets').on('click', function() {
    $("input[name='target_columns[]']").prop('checked', this.checked);
  });

  $('.js-target-table-radio').on('click', function(e) {
    $(e.target).parents('.js-target-checkbox-group').siblings('.js-target-checkbox-group').find('input[type=checkbox]').prop('checked', false);
    $(e.target).parents('.js-target-checkbox-group').siblings('.js-target-checkbox-group').find('input[type=checkbox]').prop('disabled', true);
    $(e.target).parents('.js-target-checkbox-group').find('input[type=checkbox]').prop('checked', true);
    $(e.target).parents('.js-target-checkbox-group').find('input[type=checkbox]').prop('disabled', false);
  });
});
